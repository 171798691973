import { useEffect, useState } from 'react';
import { api } from '@iblai/ibl-web-react-common';
import './App.css';

import '@iblai/ibl-web-react-common/dist/index.css';
import route from './routes';
import { AppDataContext, isJSON } from '@iblai/ibl-web-react-common';
import { RouterProvider } from 'react-router-dom';
import './i18n';

//window.api = api

const initiateAppContextData = () => {
  return {
    ...localStorage,
    userData: isJSON(localStorage['userData'])
      ? JSON.parse(localStorage['userData'])
      : null,
    ibl_user_public_metadata: isJSON(
      localStorage.getItem('ibl_user_public_metadata')
    )
      ? JSON.parse(localStorage.getItem('ibl_user_public_metadata'))
      : null,
    userMetaData: {},
  };
};

function App() {
  const [appData, setAppData] = useState(initiateAppContextData());
  useEffect(() => {
    api.initiateAxdClientsWithToken(appData.axd_token);
  }, []);
  return (
    <>
      <AppDataContext.Provider value={{ appData, setAppData }}>
        <RouterProvider router={route(appData)} />
      </AppDataContext.Provider>
    </>
  );
}

export default App;
