"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cacheEndpoints = void 0;
exports.buildQueryParams = buildQueryParams;
exports.parseJwt = parseJwt;
exports.cacheMechanism = cacheMechanism;
exports.getCurentTenant = getCurentTenant;
exports.getTenants = getTenants;
exports.switchTenant = switchTenant;
exports.isTokenExpired = isTokenExpired;
exports.saveUserTenantsDataToLocalStorage = saveUserTenantsDataToLocalStorage;
exports.setCookie = setCookie;
exports.getCookie = getCookie;
exports.removeSubdomains = removeSubdomains;
exports.isCourseInnerware = isCourseInnerware;
exports.getUnitFromCourseInnerwareURL = getUnitFromCourseInnerwareURL;
exports.getCourseIdFromURL = getCourseIdFromURL;
const config_1 = require("./config");
function buildQueryParams(params = {}) {
    let queryParams = '?';
    Object.entries(params).forEach(([key, value]) => {
        queryParams += `${key}=${value}&`;
    });
    return queryParams;
}
function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(''));
    return JSON.parse(jsonPayload);
}
function buildCacheKey(endpoint, endpointParams, paramsObj) {
    let cacheKey = endpoint + '?';
    endpointParams.forEach((param) => {
        if (paramsObj[param]) {
            cacheKey += `${param}=${paramsObj[param]}&`;
        }
    });
    return cacheKey;
}
function cacheMechanism(endpoint, paramsObj = null, data = null) {
    if (exports.cacheEndpoints.hasOwnProperty('endpoint')) {
        let cacheKey = buildCacheKey(endpoint, exports.cacheEndpoints[endpoint].params, paramsObj);
        exports.cacheEndpoints[endpoint].params.forEach((param) => {
            if (paramsObj[param]) {
                cacheKey += `${param}=${paramsObj[param]}&`;
            }
        });
        if (exports.cacheEndpoints[endpoint].hasOwnProperty('cache')) {
            const cachedData = localStorage.getItem(cacheKey);
            if (cachedData) {
                return cachedData;
            }
            else if (!data) {
                return null;
            }
            localStorage.setItem(cacheKey, data);
        }
        else if (exports.cacheEndpoints[endpoint].hasOwnProperty('invalidate')) {
            const invalidateEndpoint = exports.cacheEndpoints[endpoint].invalidate;
            exports.cacheEndpoints[invalidateEndpoint];
            const invalidateCacheKey = buildCacheKey(invalidateEndpoint, exports.cacheEndpoints[invalidateEndpoint].params, paramsObj);
            localStorage.removeItem(invalidateCacheKey);
        }
    }
    else {
        return null;
    }
}
exports.cacheEndpoints = {
    [config_1.default.IBL_EDX.FETCH_USER_COURSES]: {
        cache: true,
        params: ['username'],
    },
    [config_1.default.IBL_EDX.ENROLL_TO_COURSE]: {
        invalidate: config_1.default.IBL_EDX.FETCH_USER_COURSES,
    },
};
function getCurentTenant() {
    return JSON.parse(localStorage.getItem('current_tenant'));
}
function getTenants() {
    return JSON.parse(localStorage.getItem('tenants'));
}
function switchTenant(key) {
    const tenants = getTenants();
    let foundTenant = false;
    for (let i = 0; i < tenants.length; i++) {
        if (tenants[i].key === key) {
            localStorage.setItem('current_tenant', JSON.stringify(tenants[i]));
            localStorage.setItem('tenant', tenants[i].key);
            foundTenant = true;
            break;
        }
    }
    if (!foundTenant) {
        throw new Error('Tenant with key ' + key + ' does not exist for this user');
    }
}
function isTokenExpired() {
    const axdTokenExpires = localStorage.getItem('axd_token_expires');
    const dmTokenExpires = localStorage.getItem('dm_token_expires');
    if (axdTokenExpires) {
        const date = new Date(axdTokenExpires);
        if (date <= new Date()) {
            return true;
        }
    }
    if (dmTokenExpires) {
        const date = new Date(dmTokenExpires);
        if (date <= new Date()) {
            return true;
        }
    }
    return false;
}
function saveUserTenantsDataToLocalStorage(data, selectedTenant) {
    const tenants = data.map((tenantObj) => (Object.assign(Object.assign({}, tenantObj), { name: tenantObj.org })));
    localStorage.setItem('tenants', JSON.stringify(tenants));
    let currentTenant;
    if (selectedTenant) {
        currentTenant = tenants.find((tenant) => tenant.key === selectedTenant);
    }
    if (!currentTenant) {
        currentTenant = tenants[tenants.length - 1];
        if (currentTenant.key === 'main' && tenants.length > 1) {
            currentTenant = tenants[tenants.length - 2];
        }
    }
    localStorage.setItem('current_tenant', JSON.stringify(currentTenant));
    localStorage.setItem('tenant', currentTenant.key);
}
function setCookie(value, domain, minutes) {
    let expires = '';
    let date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
    const cookieParts = [
        `csrftoken=${value}`,
        expires,
        '; path=/',
        '; Secure',
        '; SameSite=None',
    ];
    if (domain) {
        cookieParts.push(`; domain=.${domain}`);
    }
    document.cookie = cookieParts.join('');
}
function getCookie(cName) {
    const name = cName + '=';
    const cDecoded = decodeURIComponent(document.cookie); //to be careful
    const cArr = cDecoded.split('; ');
    let res;
    cArr.forEach((val) => {
        if (val.indexOf(name) === 0)
            res = val.substring(name.length);
    });
    return res;
}
function removeSubdomains() {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    if (parts.length > 2) {
        parts.shift();
        return parts.join('.');
    }
    else {
        return parts.join('.');
    }
}
function isCourseInnerware() {
    const regex = /^\/courses\/.+\/course$/;
    return regex.test(window.location.pathname);
}
function getUnitFromCourseInnerwareURL() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const searchParam = new URLSearchParams(url.searchParams);
    return searchParam.get('unit_id');
}
function getCourseIdFromURL() {
    const regex = /^\/courses\/(.+)\/course$/;
    const match = window.location.pathname.match(regex);
    if (match && match.length > 1) {
        return match[1];
    }
    console.debug('Course ID not found in course innerware');
    return '';
}
