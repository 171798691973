import { useState, useEffect } from 'react';
import { IBLSpinLoader } from '@iblai/ibl-web-react-common';
import {
  DESCRIPTIVE_TIMEFRAMES_OPTIONS,
  PREDICTIVE_TIMEFRAMES_OPTIONS,
} from '../../hooks/helpers';

const Descriptivesimplegraphcard = ({
  data,
  dataLoaded,
  title,
  isPredictive = false,
}) => {
  // State to manage the selected time frame
  const [selectedTimeFrame, setSelectedTimeFrame] = useState(
    !isPredictive ? 'change_last_seven_days' : 'nm'
  );
  const [newValue, setNewValue] = useState(0);
  const [isPositive, setIsPositive] = useState(true);
  const [timeFrames, setTimeFrames] = useState([]);
  const [noData, setNoData] = useState(false);

  // Function to calculate and format the percentage change
  const calculateNonPredictivePercentageChange = () => {
    const {
      change_range_percent,
      change_last_seven_days_percent,
      change_last_thirty_days_percent,
    } = data?.meta;

    let percentageChange;
    if (selectedTimeFrame === 'change_last_seven_days') {
      percentageChange = change_last_seven_days_percent;
    } else if (selectedTimeFrame === 'change_last_thirty_days') {
      percentageChange = change_last_thirty_days_percent;
    } else {
      percentageChange = change_range_percent;
    }
    setNewValue(percentageChange);
    setIsPositive(Number(percentageChange) >= 0);
  };

  const calculatePredictivePercentageChange = () => {
    const { n3y_percent, ny_percent, nm_percent } = data?.meta;

    let percentageChange;
    if (selectedTimeFrame === 'nm') {
      percentageChange = nm_percent;
    } else if (selectedTimeFrame === 'ny') {
      percentageChange = ny_percent;
    } else {
      percentageChange = n3y_percent;
    }
    setNewValue(percentageChange);
    setIsPositive(Number(percentageChange) >= 0);
  };

  // Handle change in the selected time frame

  // Calculate percentage change whenever selectedTimeFrame or data changes
  useEffect(() => {
    if (!isPredictive && !data?.meta) {
      setNoData(true);
      return;
    }
    if (dataLoaded && !isPredictive) {
      calculateNonPredictivePercentageChange();
    } else if (dataLoaded && isPredictive) {
      calculatePredictivePercentageChange();
    }
  }, [selectedTimeFrame, dataLoaded]);

  useEffect(() => {
    if (!isPredictive) {
      setTimeFrames(DESCRIPTIVE_TIMEFRAMES_OPTIONS);
    } else {
      setTimeFrames(PREDICTIVE_TIMEFRAMES_OPTIONS);
    }
  }, []);

  return (
    <>
      <div className="div-block-204 l">
        <div className="table_cont ns">
          <div className={`div-block-202 w_bg ${isPredictive ? 'g' : ''}`}>
            <div className="div-block-235">
              <div className="text-block-109 o-t">
                {title}
                <br />
              </div>
              <div className="form-block-2 w-form">
                <form
                  id="email-form-2"
                  name="email-form-2"
                  data-name="Email Form 2"
                  method="get"
                  data-wf-page-id="657af3ad1367da03b3b12528"
                  data-wf-element-id="4772e990-a981-577d-88e1-a068f2afc5ba"
                >
                  <select
                    id="field-5"
                    name="field-5"
                    data-name="Field 5"
                    value={selectedTimeFrame}
                    onChange={(e) => setSelectedTimeFrame(e.target.value)}
                    className="select-field-6 w-select"
                  >
                    {timeFrames?.map((timeframe, index) => (
                      <option key={index} value={timeframe?.value}>
                        {timeframe?.label}
                      </option>
                    ))}
                  </select>
                </form>
                {/* Additional form handling components */}
              </div>
            </div>
            <div className="div-block-170">
              {!dataLoaded ? (
                <IBLSpinLoader
                  size={20}
                  color={'#FFF'}
                  containerHeight={'80px'}
                />
              ) : (
                <div className="div-block-171">
                  <div>
                    <div
                      data-default={newValue}
                      data-dn={2}
                      className="text-block-110 n"
                    >
                      {data?.total || data?.meta?.total || 0}
                    </div>
                    <div className="div-block-209">
                      <div
                        className="text-block-110 per down up"
                        prop-cardpercentagevalue=""
                      >
                        {isPositive ? '+' : '-'}
                        <span className="dn">
                          {data?.meta?.[`${selectedTimeFrame}`]}
                        </span>{' '}
                        ({data?.meta?.[`${selectedTimeFrame}_percent`]}%)
                      </div>
                      <img
                        alt=""
                        className="image-6 caret"
                        loading="lazy"
                        src="images/636934d9d5bff525e7d57ae1_arrow-up_1636934d9d5bff525e7d57ae1_arrow-up.png"
                        width={18}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Descriptivesimplegraphcard;
