import {
  createBrowserRouter,
  isRouteErrorResponse,
  useRouteError,
} from 'react-router-dom';
import DownloadsView, {
  InvitationsView,
  ActivityView,
  CoursesView,
  CreatePathwayView,
  CredentialsView,
  CredentialView,
  IndexView,
  LearnersView,
  PathwaysView,
  PathwayView,
  ProgramsView,
  ProgramView,
  PublicProfileView,
  RegisteredUsersView,
  SkillsView,
} from './views';
import Error403 from './errors/pages/error403';
import Error404 from './errors/pages/error404';

import {
  isJSON,
  Logout,
  SsoLogin,
  getUserDataFromCookie,
  CompleteLogin,
} from '@iblai/ibl-web-react-common';
import ActiveUsersView from './views/ActiveUsersView';
import EnrollmentsView from './views/EnrollmentsView';
import UnenrollmentsView from './views/UnenrollmentsView';
import CourseCompletionView from './views/CourseCompletionView';
import TimeSpentView from './views/TimeSpentView';
import GradingView from './views/GradingView';
import DashboardView from './views/DashboardView/DashboardView';
import ProfileView from './views/ProfileView';
import CredentialsListView from './views/CredentialsListView';
import StudioAuthoringCoursesView from './views/StudioAuthoringCoursesView';
import StudioAuthoringCourseAboutView from './views/StudioAuthoringCourseAboutView';
import StudioAuthoringCourseInstructorView from './views/StudioAuthoringCourseInstructorView';
import StudioAuthoringProgramAboutView from './views/StudioAuthoringProgramAboutView';
import StudioAuthoringProgramsView from './views/StudioAuthoringProgramsView';
import StudioAuthoringSettingsView from './views/StudioAuthoringSettingsView';

const authCheckFn = (shouldBeAdmin, shouldBeLoggedIn, appData) => {
  let currentTenantData = isJSON(appData.current_tenant)
    ? JSON.parse(appData.current_tenant)
    : appData?.current_tenant;
  return async () => {
    const userDataFromCookie = getUserDataFromCookie();
    if (
      shouldBeLoggedIn &&
      (!appData?.axd_token ||
        !appData?.dm_token ||
        !userDataFromCookie ||
        userDataFromCookie?.user_id !== appData.userData?.user_id)
    ) {
      throw new Response(JSON.stringify({ shouldBeLoggedIn }), {
        status: 401,
      });
    }
    if (shouldBeAdmin && !currentTenantData) {
      throw new Response(JSON.stringify({ shouldBeLoggedIn }), {
        status: 401,
      });
    }
    if (shouldBeAdmin && currentTenantData && !currentTenantData?.is_admin) {
      throw new Response('Not Admin', { status: 403 });
    }
    return [];
  };
};

const ErrorPage = () => {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <Error404 />;
    } else if (error.status === 403) {
      return <Error403 redirect={process.env.REACT_APP_IBL_SPA_SKILLS_URL} />;
    } else if (error.status === 401) {
      if (JSON.parse(error.data)?.shouldBeLoggedIn) {
        if (process.env.REACT_APP_IBL_IDP_URL) {
          window.location.href = process.env.REACT_APP_IBL_IDP_URL;
        } else {
          window.location.href = `${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${window.location.origin}`;
        }
      }
    }
  }
};

const route = (appData, initializeApp) =>
  createBrowserRouter([
    {
      path: '/',
      errorElement: <ErrorPage />,
      children: [
        {
          path: '',
          element: <DashboardView />,
          loader: authCheckFn(true, true, appData),
          children: [
            {
              path: '',
              element: <IndexView />,
            },
            {
              path: 'per-learner/learners',
              element: <LearnersView />,
            },
            {
              path: 'per-learner/learners/:username',
              element: <ProfileView />,
              children: [
                {
                  path: 'activity',
                  element: <ActivityView />,
                },
                {
                  path: 'credentials',
                  element: <CredentialsView />,
                },
                {
                  path: ':credentials/:credentialID',
                  element: <CredentialView />,
                },
                {
                  path: 'pathways/:pathwayID',
                  element: <PathwayView />,
                },
                {
                  path: 'pathways/new',
                  element: <CreatePathwayView />,
                },
                {
                  path: 'skills',
                  element: <SkillsView />,
                },
                {
                  path: 'pathways',
                  element: <PathwaysView />,
                },
                {
                  path: 'courses',
                  element: <CoursesView />,
                },
                {
                  path: 'programs',
                  element: <ProgramsView />,
                },
                {
                  path: 'programs/:programID',
                  element: <ProgramView />,
                },
                {
                  path: 'public-profile',
                  element: <PublicProfileView />,
                },
              ],
            },
            {
              path: 'audience/registered-users',
              element: <RegisteredUsersView />,
            },
            {
              path: 'audience/active-users',
              element: <ActiveUsersView />,
            },
            {
              path: 'audience/enrollments',
              element: <EnrollmentsView />,
            },

            {
              path: 'audience/unenrollments',
              element: <UnenrollmentsView />,
            },
            {
              path: 'engagement/course-completion',
              element: <CourseCompletionView />,
            },
            {
              path: 'engagement/time-spent',
              element: <TimeSpentView />,
            },
            {
              path: 'performance/grading',
              element: <GradingView />,
            },
            {
              path: 'performance/credentials',
              element: <CredentialsListView />,
            },
            {
              path: 'studio/authoring/courses',
              element: <StudioAuthoringCoursesView />,
            },
            {
              path: 'studio/authoring/courses/:courseID',
              element: <StudioAuthoringCourseAboutView />,
            },
            {
              path: 'studio/authoring/courses/:courseID/instructor',
              element: <StudioAuthoringCourseInstructorView />,
            },
            {
              path: 'studio/authoring/programs',
              element: <StudioAuthoringProgramsView />,
            },
            {
              path: 'studio/authoring/programs/:programID',
              element: <StudioAuthoringProgramAboutView />,
            },
            {
              path: 'studio/settings',
              element: <StudioAuthoringSettingsView />,
            },

            {
              path: 'downloads',
              element: <DownloadsView />,
            },
            {
              path: 'invites',
              element: <InvitationsView />,
            },
          ],
        },
        {
          path: 'login/complete',
          element: <CompleteLogin />,
        },
        {
          path: 'sso-login',
          element: <SsoLogin />,
          loader: authCheckFn(false, false, appData),
        },
        {
          path: 'logout',
          element: (
            <Logout
              redirectTo={`${process.env.REACT_APP_IBL_SPA_AUTH_URL}/login?redirect-to=${window.location.origin}/sso-login`}
            />
          ),
          loader: authCheckFn(false, false, appData),
        },
      ],
    },
  ]);

export default route;
