import { useEffect, useRef, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '@iblai/ibl-web-react-common/dist/index.css';
import { getProgramMeta } from '../hooks/utils/studio';
import { getTenant } from '../hooks/helpers';
import { postInvite } from '../hooks/utils/invitations';

const ProgramView = () => {
  const { programID } = useParams();
  const iframeRef = useRef(null);
  const [pageTitle, setPageTitle] = useState('');
  const [iframeRandom, setIframeRandom] = useState(0);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [programName, setProgramName] = useState('');
  const [isInviteActive, setIsInviteActive] = useState(false);
  const [userNameOrEmail, setUserNameOrEmail] = useState('');
  const [inviteProgramID, setInviteProgramID] = useState('');
  const [callBackMsgStatus, setCallBackMsgStatus] = useState('');
  const [callBackMsg, setCallBackMsg] = useState('');

  const studioURL = `${process.env.REACT_APP_IBL_STUDIO_URL}programs/${getTenant()}/${inviteProgramID}`;
  const viewLiveURL = `${process.env.REACT_APP_IBL_SKILLS_IFRAME_WIDGET_ORIGIN_URL}/programs/${programID}`;
  const sendMessage = () => {
    return setTimeout(() => {
      iframeRef.current.contentWindow.postMessage(
        { reason: 'PROFILE_DISPLAY', message: { programID } },
        '*'
      );
      iframeRef.current.contentWindow.postMessage({
        reason: 'TOKEN',
        message: { token: localStorage.getItem('token') },
      });
    }, 1000);
  };

  const handleInviteBtnClick = () => {
    setIsInviteActive(!isInviteActive);
  };

  const clearForm = () => {
    setUserNameOrEmail('');
    setCallBackMsg('');
    setCallBackMsgStatus('');
  };

  const handleSubmitInvite = () => {
    let data = {
      userNameOrEmail: userNameOrEmail,
      type: 'ibl-dm.public.dl_catalog_app_program',
      id: inviteProgramID,
    };
    postInvite(data, (inviteData) => {
      if (inviteData) {
        setCallBackMsg('Invitation sent successfully');
        setCallBackMsgStatus('success');
      } else {
        setCallBackMsg('Invitation failed');
        setCallBackMsgStatus('error');
      }
    });
  };

  useEffect(() => {
    getProgramMeta(programID, (name, programID) => {
      setProgramName(name);
      setInviteProgramID(programID);
    });
    let timeoutIds = [];
    if (iframeLoaded) {
      sendMessage();
    } else {
      iframeRef.current.style.visibility = 'hidden';
      iframeRef.current.addEventListener('load', () => {
        setIframeLoaded(true);
        timeoutIds.push(
          setTimeout(() => {
            iframeRef.current.style.visibility = '';
          }, 1000)
        );
        timeoutIds.push(sendMessage());
      });
    }
    setIframeRandom(iframeRandom + 1);
    window.addEventListener('message', (event) => {
      if (event.data.reason === 'PROFILE_DISPLAY') {
        const page = event.data.message.page;
        setPageTitle(`${page}`);
      }
      if (event.data.reason === 'IFRAME_DIMENSION') {
        // iframeRef.current.style.height = `${event.data.message.height + 100}px`;
      }
    });
    return () => {
      timeoutIds.forEach((timeoutId) => clearTimeout(timeoutId));
    };
  }, [programID]);

  return (
    <>
      <div
        className={`popup_div-nm_popup invite-popup ${isInviteActive ? 'active' : ''}`}
      >
        <div className="popup_cont-sk">
          <div className="hf_popup">
            <div className="div-block-147 new_msg">
              <div className="text-block-135 mg-0">
                Invite User
                <strong>
                  <br />
                </strong>
              </div>
            </div>
            <div className="div-block-228">
              <div className="form-block-7 w-form">
                <form
                  data-name="Email Form 5"
                  data-wf-element-id="1652ea72-9a5b-2d7f-eb57-581687993f77"
                  data-wf-page-id="657aff678982edbdb39ddab6"
                  id="email-form-5"
                  method="get"
                  name="email-form-5"
                >
                  <label className="text-block-139-bd-f-soc" htmlfor="name-2">
                    User *
                  </label>
                  <input
                    className="text-field-3 w-input"
                    data-name="Name 2"
                    id="name-2"
                    maxLength={256}
                    name="name-2"
                    placeholder="Enter a username or an email"
                    type="text"
                    value={userNameOrEmail}
                    onChange={(e) => setUserNameOrEmail(e.target.value)}
                  />
                </form>
              </div>
              <div className={`callback-msg ${callBackMsgStatus}`}>
                {callBackMsg}
              </div>
            </div>
            <div className="div-block-147 new_msg">
              <div className="div-block-264 f-w">
                <div
                  className="div-block-207 first active follow-user alt-btn cancel-invite"
                  onClick={() => {
                    setIsInviteActive(false);
                    clearForm();
                  }}
                >
                  <div className="text-block-111 red-text cf">Cancel</div>
                </div>
                <div
                  className="div-block-207 first active follow-user redeem-btn mg-l16"
                  onClick={handleSubmitInvite}
                >
                  <div className="text-block-111 red-text cf">Invite</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="div-block-183">
        <div className="div-block-205">
          <div className="div-block-206"></div>
          <div
            className="text-block-113"
            style={{ textTransform: 'capitalize' }}
          >
            PROGRAM <span> | {programName}</span>
          </div>
        </div>
        <div className="div-block-308">
          <div
            className="div-block-207 first active follow-user invite-btn alt-btn"
            onClick={handleInviteBtnClick}
          >
            <div className="text-block-111">Invite</div>
          </div>
          <Link
            to={studioURL}
            target="_blank"
            prop-events-value-onclick="handleAddNewCourseClick"
            prop-events-names="onClick"
            href="#"
            className="link-block-22 w-inline-block"
          >
            <div className="div-block-207 first active follow-user alt-btn">
              <div className="text-block-111">Edit</div>
            </div>
          </Link>
          <Link
            to={viewLiveURL}
            target="_blank"
            prop-events-names="onClick"
            href="#"
            className="link-block-22 w-inline-block"
          >
            <div className="div-block-207 first active follow-user">
              <div className="text-block-111">View Live</div>
            </div>
          </Link>
        </div>
      </div>
      <iframe
        title={`Program for ${programID}`}
        // key={iframeRandom}
        ref={iframeRef}
        src={`${process.env.REACT_APP_IBL_SKILLS_IFRAME_WIDGET_ORIGIN_URL}/programs/${programID}`}
      ></iframe>
    </>
  );
};

export default ProgramView;
